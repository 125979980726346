<template>
  <div>
    <!-- 导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>生活管理</el-breadcrumb-item>
      <el-breadcrumb-item>居家管理</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片试图 -->
    <el-form :model="queryParams" layout="inline" :inline="true">
      <el-row :gutter="24">
        <el-col :md="12" :sm="12">
          <el-form-item label="查询类型">
            <el-select
              v-model="planType"
              placeholder="请选择"
              @change="getPlanType"
            >
              <el-option
                v-for="item in planTypeList"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="12" :sm="12" v-if="this.changeViewType == 2">
          <el-form-item label="字典名称" prop="dictName">
            <el-date-picker
              v-model="monthValue"
              type="month"
              @change="changeMonth"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="12" :sm="12" v-if="this.changeViewType == 1">
          <el-form-item label="查询时间" prop="recodeMonth" v-if="this.caPlanType != 4">
            <el-date-picker
              @change="listplanButton"
              v-model="queryParams.recodeMonth"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="month"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="查询时间" prop="recodeMonth" v-else>
            <el-date-picker
              @change="listplanButton"
              v-model="queryParams.year"
              value-format='yyyy'
              type="year"
              :clearable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="6" :sm="24" style="width: 70%">
          <el-button type="primary" @click="listplanButton()">搜索</el-button>
          <el-button type="primary" @click="primaryRest()">重置</el-button>
        </el-col>
      </el-row>

      <el-form-item class="show_type">
        <el-button
          type="warning"
          icon="el-icon-add"
          size="min"
          @click="testView('1')"
          >列表视图</el-button
        >
        <el-button
          v-if="this.caPlanType == 1 || this.caPlanType == 4"
          type="warning"
          icon="el-icon-add"
          size="min"
          @click="testView('2')"
          >日历视图</el-button
        >
      </el-form-item>
    </el-form>

    <!-- 按钮区 -->
    <el-row :gutter="20" style="margin: 9px 2px">
      <el-button
        type="warning"
        icon="el-icon-add"
        size="min"
        @click="addPlanShow"
        >新增</el-button
      >
      <el-button
        type="warning"
        icon="el-icon-add"
        size="min"
        @click="editPlanShow"
        >编辑</el-button
      >
      <!-- <el-button type="warning" icon="el-icon-add" size="min">删除</el-button> -->
      <el-button
        type="warning"
        icon="el-icon-add"
        size="min"
        v-if="this.caPlanType == 3"
        disabled
        >查询作息时间</el-button
      >
      <el-button
        type="warning"
        icon="el-icon-add"
        size="min"
        v-if="this.caPlanType == 4"
        disabled
        >填写进度</el-button
      >
      <el-button
        type="warning"
        icon="el-icon-add"
        size="min"
        v-if="this.caPlanType == 1 || this.caPlanType == 3"
        disabled
        >查看明日计划</el-button
      >
      <el-button type="warning" icon="el-icon-add" size="min" disabled
        >发送计划</el-button
      >
    </el-row>
    <!-- 列表区域 -->
    <div v-if="this.changeViewType == 1">
      <el-table
        :data="dictList"
        ref="multipleTable"
        @row-dblclick="dataBackFillGoods"
      >
        <el-table-column label="序号" align="center" width="65">
          <template slot-scope="scope">
            <el-radio
              :label="scope.$index"
              v-model="radio"
              @change.native="getCurrentRow(scope.row)"
            />
          </template>
        </el-table-column>
        
        <el-table-column label="版本" align="center" prop="version" />
        <el-table-column
          label="目标时间"
          align="center"
          prop="targetTimeFrom"
          v-if="this.planType == 1"
        />
        <el-table-column
          label="模式"
          align="center"
          prop="model"
          v-if="this.planType == 3"
        />
        <el-table-column
          label="每周几执行"
          align="center"
          prop="day"
          v-if="this.planType == 2"
        />
        <el-table-column
          label="等级"
          align="center"
          prop="grade"
          v-if="this.planType != 3"
        />
        <el-table-column
          label="标题"
          align="center"
          prop="title"
          v-if="this.planType != 3"
        />

        <el-table-column
          label="时长"
          align="center"
          prop="duration"
          v-if="this.planType == 1 || this.planType == 2"
        />
        <el-table-column
          label="预算"
          align="center"
          prop="budget"
          v-if="this.planType != 3"
        />
        <el-table-column
          label="进度"
          align="center"
          prop="progressRate"
          v-if="this.planType != 3"
        />
        <el-table-column
          label="标签"
          align="center"
          prop="label"
          v-if="this.planType != 3"
        />
        <el-table-column
          label="开始时间"
          align="center"
          prop="planStartTime"
          v-if="this.planType == 4"
        />
        <el-table-column
          label="结束时间"
          align="center"
          prop="planEndTime"
          v-if="this.planType == 4"
        />
        <el-table-column label="详情" align="center" v-if="this.planType == 4">
          <template slot-scope="scope">
            <el-button
              style="color: #108ee9; cursor: pointer"
              @click="showTripButton(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
       
        <el-table-column
          label="目标时间"
          align="center"
          prop="targetTime"
          v-if="this.planType == 4"
        />
       
        <el-table-column
          label="截止时间"
          align="center"
          prop="deadlineTime"
          v-if="this.planType == 4"
        />
        
        <el-table-column label="状态" align="center" prop="status" />
        <el-table-column label="备注" align="center" prop="remark" />
        
      </el-table>
      <pagination
        :total="queryParams.total"
        :page-size.sync="queryParams.pageSize"
        :current-page.sync="queryParams.currentPage"
      />
    </div>

    <!-- 日历视图表 -->
    <div v-if="this.changeViewType == 2 || this.changeViewType == 4">
      <span style="margin: 5px 10px">{{ noweDataFrom }}</span>
      <el-calendar style="margin: 5px 10px" v-model="nowdate">
        <template slot="dateCell" slot-scope="{ date, data }">
          <p :class="data.isSelected ? 'is-selected' : ''">
            {{ data.day.slice(-2) }} {{ data.isSelected ? "✔️" : "" }}
          </p>
          <div v-for="(i, index) in dayList" :key="index">
            <div v-if="data.day == i.targetTimeFrom" class="reminder_class">
              <el-button
                size="mini"
                icon="el-icon-search"
                circle
                @click="getInfoButton(i)"
              ></el-button>
              <span style="color: #e6a23c" v-if="i.category == 1">{{
                i.title
              }}</span>
              <span style="color: #e6a23c" v-if="i.category == 4"
                >{{ i.planTitle }}--{{ i.title }}</span
              >
            </div>
          </div>
        </template>
      </el-calendar>
    </div>

    <restModel ref="restModelFrom" />
    <addStructurePlan ref="addStructurePlanFrom" />
    <bondStructurePlan ref="bondStructurePlanFrom" />
    <bondStructurePlanInfo ref="bondStructurePlanInfoFrom" />
    <addCurrency ref="addCurrencyFrom" />
  </div>
</template>
<script>
import { listplanApi, listplansApi } from "@/api/work/plan/plan.js";
import { getWorkEnumList } from "@/api/commonApi/commonInternal/commonInternal";
import addStructurePlan from "./modules/addStructurePlan";
import bondStructurePlan from "./modules/bondStructurePlan";
import bondStructurePlanInfo from "./modules/bondStructurePlanInfo";
import restModel from "./modules/restModel";
import addCurrency from "./modules/addCurrency";
import format from "@/plugins/format.js";
export default {
  components: {
    addCurrency,
    restModel,
    addStructurePlan,
    bondStructurePlan,
    bondStructurePlanInfo,
  },
  data() {
    return {
      nowdate: new Date(),
      radio: null,
      dictList: [],
      caPlanType: 1,
      planType: "1",
      dataColumn: this.dayjs(this.dayjs()).format("YYYY-MM-DD"),
      queryParams: {
        recodeMonth: new Date(),
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      changeViewType: 1,
      planTypeList: [],
      uniqueId: null,
      dayList: [],
      noweDataFrom: format(new Date(), "YYYY-MM"),
    };
  },
  watch: {
    nowdate() {
      var year = this.nowdate.getFullYear();
      var month = this.nowdate.getMonth() + 1;
      var date = this.nowdate.getDate();
      if (date >= 1 && date <= 9) {
        //日如果小于10就补个0
        date = "0" + date;
      }
      if (month >= 1 && month <= 9) {
        //月如果小于10就补个0
        month = "0" + month;
      }
      //这个时间是变了的
      console.log(this.nowdate);
      // console.log(year + "-" + month + "-" + date); // 打印出日历选中了哪年哪月
      this.getCalendarList(); //  调用接口，把拼接好的参数传到后台
      this.noweDataFrom = format(this.nowdate, "YYYY-MM");
    },
    "queryParams.currentPage"(val) {
      this.listplanButton();
    },
    "queryParams.pageSize"(val) {
      this.listplanButton();
    },
  },
  computed: {
    monthValue: {
      get: function () {
        return this.nowdate;
      },
      set: function (newValue) {
        this.nowdate = newValue;
      },
    },
  },
  created() {
    this.getWorkEnumButton();
    this.listplanButton();
  },
  methods: {
    //月下拉选框
    changeMonth() {
      this.nowdate = new Date(this.nowdate);
    },
    getCalendarList() {
      this.queryParams.category = 1;
      if (this.queryParams.category == 4) {
      } else {
        this.queryParams.recodeMonth = format(
          this.nowdate,
          "YYYY-MM-DD HH:mm:ss"
        );
      }

      this.listplanButton();
    },
    getPlanType(value) {
      if (value != 1) {
        this.changeViewType = 1;
      }
      this.caPlanType = value;
      this.uniqueId = null;
      this.radio = null;
      this.listplanButton();
    },
    testView(type) {
      this.changeViewType = type;
      this.queryParams.category = this.planType;
      if (this.queryParams.category == 4) {
      } else {
        this.queryParams.recodeMonth = format(
          this.queryParams.recodeMonth,
          "YYYY-MM-DD HH:mm:ss"
        );
      }

      this.listplanButton();
    },
    getWorkEnumButton() {
      getWorkEnumList({ enumName: "PlanTypeEnum" }).then((res) => {
        this.planTypeList = res.result;
      });
    },

    listplanButton() {
      this.queryParams.category = this.planType;
      if (this.queryParams.category == 4) {
        this.queryParams.recodeMonth = null;
      } else {
        this.queryParams.recodeMonth = format(
          this.queryParams.recodeMonth,
          "YYYY-MM-DD HH:mm:ss"
        );
      }
      console.log("view", this.changeViewType);
      if (this.changeViewType == 2) {
        listplansApi(this.queryParams).then((res) => {
          this.dictList = [];
          //日历结果 这里进行改动
          this.dayList = res.result;
          console.log("--", this.dayList);
        });
      } else {
        listplanApi(this.queryParams).then((res) => {
          this.dictList = res.result.records;
          //日历结果 这里进行改动
          this.dayList = [];
          // res.result.records;
          this.queryParams.total = res.result.total;
        });
      }
    },

    addPlanShow() {
      if (this.caPlanType == 2) {
        this.$refs.addCurrencyFrom.show(null, 1, this.caPlanType);
      }
      if (this.caPlanType == 3) {
        this.$refs.restModelFrom.show(null, 1, this.caPlanType);
      }
      if (this.caPlanType == 4) {
        this.$refs.bondStructurePlanFrom.show(null, 1, this.caPlanType);
      }
      if (this.caPlanType == 1) {
        this.$refs.addStructurePlanFrom.show(null, 1, this.caPlanType);
      }
    },
    editPlanShow() {
      if (this.uniqueId == null) return this.$message.error("请选择有效的一列");
      if (this.caPlanType == 2) {
        this.$refs.addCurrencyFrom.show(this.uniqueId, 2, this.caPlanType);
      }
      if (this.caPlanType == 3) {
        this.$refs.restModelFrom.show(this.uniqueId, 2, this.caPlanType);
      }
      if (this.caPlanType == 4) {
        this.$refs.bondStructurePlanFrom.show(
          this.uniqueId,
          2,
          this.caPlanType
        );
      }
      if (this.caPlanType == 1) {
        this.$refs.addStructurePlanFrom.show(this.uniqueId, 2, this.caPlanType);
      }
    },

    primaryRest() {
      this.$refs["queryParams"].resetFields();
      this.listplanButton();
    },
    getCurrentRow(row) {
      this.uniqueId = row.uniqueId;
    },
    dataBackFillGoods(row, column) {
      console.log(row);
      console.log(column);
    },
    dayCurrent(val) {
      if (this.dayList.indexOf(val) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    getInfoButton(row) {
      this.uniqueId = row.uniqueId;
      this.editPlanShow();
    },
    showTripButton(row) {
      this.$refs.bondStructurePlanInfoFrom.show(row, 1);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-calendar__title {
  display: none !important;
}
/deep/.el-calendar__button-group {
  margin: auto;
}
/deep/.show_type {
  float: right;
}
// /deep/.reminder_class {
//   width: 120px;
// }
</style>